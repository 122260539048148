import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./util/authConfig";
import { BrowserRouter } from 'react-router-dom';
import { AppLocalStore } from "./util/Constants";


const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise().then(authResult => {
  if (authResult != null) {
      localStorage.setItem(AppLocalStore.Token, authResult.accessToken);
  }
});

ReactDOM.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
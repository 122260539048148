import {GridColumns} from "@material-ui/data-grid";
import {Tooltip} from "@material-ui/core";
import './tablecolumns.css'

export const columns: GridColumns = [
    {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        width: 150
    },
    {
        field: 'ticketNumber',
        headerName: 'Ticket',
        width: 150,
    },
    {
        field: 'ipAddress',
        headerName: 'IP Address',
        width: 200,
    },
    {
        field: 'isUserEmail',
        headerName: 'Is User Email',
        type: 'boolean',
        width: 175,
    },
    {
        field: 'userEmail',
        headerName: 'User Email',
        width: 250,
    },
    {
        field: 'customerName',
        headerName: 'Customer',
        width: 200,
    },
    {
        field: 'submissionEmployee',
        headerName: 'Submission By',
        width: 250,
    },
    {
        field: 'reason',
        headerName: 'Reason',
        width: 300,
        renderCell: (params: any) =>  (
            <Tooltip title={params.value}>
                <span className="table-cell-truncate">{params.value}</span>
            </Tooltip>
        ),
    },
    {
        field: 'deactivatedBy',
        headerName: 'Deactivated By',
        width: 250,
    },
    {
        field: 'creationDate',
        headerName: 'Date Submitted',
        width: 200,
    }
];


import {Alert, Box, Checkbox, Fab, FormControlLabel, Snackbar} from "@material-ui/core";
import {DataGrid, GridColumns, GridRowId} from "@material-ui/data-grid";
import React, {useState} from "react";
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

type TableProps = {
    tableData: any[];
    loading: boolean;
    columns: GridColumns;
    onDeactivatePress: (rows: GridRowId[]) => void;
    openSnackBar: boolean;
    snackBarSuccess: boolean;
    handleSnackBarClose: (close: boolean) => void;
    showActiveOnly: (event: any) => void;
}

export const SubmissionsTable = (props: TableProps) => {
    const emptyRowIdArray: GridRowId[] = [];
    const [ rowsSelected, setRowsSelected ] = useState(emptyRowIdArray);

    const onDeactivatePress = () => {
        props.onDeactivatePress(rowsSelected);
    };

    const handleClose = (event: React.SyntheticEvent) => {
        props.handleSnackBarClose(true);
    };

    return (
        <Box style={{ height: 700, width: '100%', justifyContent: 'center' }}>
            <Box style={{alignItems: 'center'}}>
                <FormControlLabel
                    control={
                        <Checkbox name="checkedC"
                                  onChange={props.showActiveOnly}
                                  color="primary"
                                  defaultChecked={true}
                        />}
                    style={{justifyContent: 'center', width: '100%'}}
                    label="Active Only" />
            </Box>
            <Snackbar open={props.openSnackBar} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}>
                <Alert onClose={handleClose} severity={props.snackBarSuccess ? 'success' : 'error'}>
                    {props.snackBarSuccess ? 'Deactivation successful!' : 'There was an API error!'}
                </Alert>
            </Snackbar>
            <DataGrid
                rows={props.tableData}
                columns={props.columns}
                pageSize={20}
                checkboxSelection
                disableSelectionOnClick
                style={{ backgroundColor: 'white' }}
                loading={props.loading}
                onError={(error) => console.log('Error: ', error)}
                onSelectionModelChange={setRowsSelected}
            />
            {
                rowsSelected.length > 0 &&
                <Fab
                    style={{ position: "fixed", left: 40, bottom: 15 }}
                    variant="extended"
                    color="primary"
                    onClick={onDeactivatePress}
                >
                    <RemoveCircleOutline/>
                    Deactivate
                </Fab>
            }
        </Box>
    );
}
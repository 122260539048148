import { Navigate } from 'react-router-dom';
import AuthLayout from './components/AuthLayout';
import PageLayout from './components/PageLayout';
import { Audit } from './pages/Audit';
import { Submissions } from './pages/Submissions';
import { LoginScreen } from './screens/LoginScreen';


const routes = (isLoggedin: boolean) => [
  {
    path: '/app',
    element: isLoggedin ? <PageLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'submissions', element: <Submissions /> },
      { path: 'audit', element: <Audit /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: "/",
    element: !isLoggedin ? <AuthLayout /> : <Navigate to="/app/submissions" />,
    children: [
      { path: "login", element: <LoginScreen /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> }
    ]
  }
];

export default routes;
import React from "react";
import { Outlet } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core';
import NavBar from "./NavBar/NavBar";


const MainLayoutRoot = styled('div')(
    ({ theme }) => ({
        backgroundColor: 'rgb(229, 229, 229, 0.05)',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    })
);

const MainLayoutWrapper = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 100,
    paddingBottom: 64
});

const MainLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const MainLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
});


const PageLayout = () => {
    return (
        <>
            <MainLayoutRoot>
                <NavBar/>
                <MainLayoutWrapper>
                    <MainLayoutContainer>
                        <MainLayoutContent>
                            <Outlet />
                        </MainLayoutContent>
                    </MainLayoutContainer>
                </MainLayoutWrapper>
            </MainLayoutRoot>
        </>
    );
};

export default PageLayout;
import { AxiosResponse } from "axios";
import { useState } from "react";
import { Result } from "../types";

interface IApiResponse {
  message?: string;
  loading: boolean;
  request: Function;
}

const useApi = (apiFunc: Function): IApiResponse => {
  const [loading, setLoading] = useState(false);

  // return type is not enforced by typescript when we do something like this..
  // must look more into it -Ali
  const request = async (...args: any[]): Promise<Result<AxiosResponse>> => {
    let result: Result<AxiosResponse>;
    try {
      setLoading(true);
      const response: AxiosResponse = await apiFunc(...args);
      setLoading(false);
      result = { type: "success", value: response };
      return result;
    } catch (error) {
      // we should introduce better error handling
      // are there any situations where we'd want to throw?
      result = {
        type: "error",
        error: error
        // error: error.response.data ? error.response.data : error.response
      };

      const errorMsg = `API Error calling ${error.config.baseURL}${
        error.config.url
      }\n\nResponse: (${
        error.response.status
      }) ${error.response.data?.replaceAll("!CRLF!", "\n")}`;
      console.log(errorMsg);

      return result;
    } finally {
      // clean up
      setLoading(false);
    }
  };

  return { loading, request };
};

export default useApi;

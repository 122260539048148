import React from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AppBar, Box, Tab, Tabs, Toolbar, Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PtgLogo from '../../resources/ptg-logo.png';
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const NavBar = (props: any) => {
  const { instance } = useMsal();
  const [activeTab, setActiveTab] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('audit')) {
      setActiveTab(1);
    }
  }, [location]);

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/"
    })
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
    if (newValue === 1) {
      navigate('/app/audit')
    } else {
      navigate('/app/submissions')
    }
  };

  return (
    <AppBar
      elevation={5}
      style={{ backgroundColor: '#FFFFFF' }}
      {...props}
    >
      <Toolbar sx={{ height: 100, display: 'flex' }}>
        <RouterLink to="/">
          <Box width={225} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={PtgLogo} height={44} />
          </Box>
        </RouterLink>
        <Box>
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab label="Submit" />
            <Tab label="Review" />
          </Tabs>
        </Box>
        <Box style={{display: 'flex', flex: 3, justifyContent: 'flex-end' }}>
          <Button onClick={handleLogout}>
            Sign Out<ExitToAppIcon style={{paddingLeft: 3}}/>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
import React from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';

export const TabBar = (props: any) => {
    const [activeForm, setActiveForm] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveForm(newValue);
        props.handlePress(newValue);
    };

    return (
        <Box style={{ width: '100%', alignItems: 'center', paddingTop: 30 }}>
            <Tabs value={activeForm} onChange={handleChange} centered>
                <Tab label="IP Address" />
                <Tab label="Country" />
            </Tabs>
        </Box>
    );
}

import * as Yup from 'yup';
import {ObjectSchema} from 'yup';

const emailString = 'email';

export const getCountryValidationSchema = (submissionType: string): ObjectSchema<object | undefined, object> => {
    return Yup.object().shape(
        {
            ticketNumber: Yup.string()
                .matches(/^\d+$/, 'Ticket Number must only be a numeric value')
                .min(7, 'Ticket Number must be at least 7 characters')
                .max(255).required('Ticket Number is required'),
            country: Yup.string().max(255).required('Country is required'),
            userEmail: Yup.string().when('ticketNumber', {
                is: values => submissionType === emailString,
                then: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                otherwise: Yup.string()
            }),
            customerName: Yup.string().when('ticketNumber', {
                is: values => submissionType !== emailString,
                then: Yup.string().max(255).required('Customer is required'),
                otherwise: Yup.string()
            }),
            reason: Yup.string().max(255).required('Reason is required'),
        });
}


export const getIpValidationSchema = (submissionType: string): ObjectSchema<object | undefined, object> => {
    return Yup.object().shape(
        {
            ticketNumber: Yup.string()
                .matches(/^\d+$/, 'Ticket Number must only be a numeric value')
                .min(7, 'Ticket Number must be at least 7 characters')
                .max(255).required('Ticket Number is required'),
            ipAddress: Yup.string()
                .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/,
                    { message:'Invalid IP address', excludeEmptyString: true })
                .max(255).required('IP Address is required'),
            userEmail: Yup.string().when('ticketNumber', {
                is: values => submissionType === emailString,
                then: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                otherwise: Yup.string()
            }),
            customerName: Yup.string().when('ticketNumber', {
                is: values => submissionType !== emailString,
                then: Yup.string().max(255).required('Customer is required'),
                otherwise: Yup.string()
            }),
            reason: Yup.string().max(255).required('Reason is required'),
        });
}
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { TabBar } from '../components/TabBar';
import { IpSubmissionsTable } from '../components/Tables/IpSubmissionsTable';
import { CountrySubmissionsTable } from '../components/Tables/CountrySubmissionsTable';


export const Audit = (props: any) => {
    const [activeTable, setActiveTable] = React.useState(0);

    const handleTabChange = (newValue: number) => {
        setActiveTable(newValue);
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
            <Box style={{ width: '100%', alignItems: 'center', paddingTop: 30 }}>
                <TabBar handlePress={handleTabChange}/>
            </Box>
            <Box style={{height: '100%', width: '100%', padding: 30}}>
                { activeTable === 0 ? <IpSubmissionsTable/> : <CountrySubmissionsTable/> }
            </Box>
        </Box>
    );
}

import { LogLevel } from "@azure/msal-browser";


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: 'b14c3220-beaf-4ac3-a9f6-5c979e6c1f98', // PROD
        // clientId: 'b2933489-5a6b-4d06-a5e2-cd05d0eefe5b', // DEV
        authority: "https://login.microsoftonline.com/4712f1d3-0211-4689-80bc-b2c5d6c4c4b4",
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

// Scope for API Access token
export const loginRequest = {
    scopes: ["api://ea97b6e0-8cb3-4492-b116-db622e304b55/RiskySignInsAPIAccess"]
};


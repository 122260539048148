import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useIsAuthenticated } from "@azure/msal-react";
import theme from './theme';
import routes from './routes';
import GlobalStyles from './components/GlobalStyles';


const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const routing = useRoutes(routes(isAuthenticated));
  // const routing = useRoutes(routes(true));


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  )
}

export default App;

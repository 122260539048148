import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../util/authConfig";
import { Button } from "@material-ui/core";
import { IPublicClientApplication } from "@azure/msal-browser";


function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest)
        .then((loginResponse: any) => {})
        .catch((e: any) => {
            console.error("Error ",e);
        });
}

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="contained" onClick={() => handleLogin(instance)}>Sign in</Button>
    );
}
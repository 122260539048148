// Retrieve whitelisted country values
import {AxiosResponse} from "axios";
import apiClient from "../ApiClient";
import * as endpoints from "./endpoints";
import {ApiResponse, IpSubmission, IpValue} from "../../types";

export const getWhitelistedIpValues =
    (): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.get(endpoints.getIpWhitelist);
    }

export const postSubmitIp =
    (submission: IpSubmission): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.post(endpoints.SubmitIp, submission)
    }

export const postDeactivateIp =
    (ipValues: IpValue[]): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.post(endpoints.DeactivateIp, ipValues);
    }

export const getParticipatingCustomers =
    (): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.get(endpoints.getParticipatingCustomers);
    }
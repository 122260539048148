import React from "react";
import { Outlet } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core';
import NavBar from "./NavBar/NavBar";


const AuthLayoutRoot = styled('div')(
    ({ theme }) => ({
        backgroundColor: '#117492',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    })
);

const AuthLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
});


const AuthLayout = () => {
    return (
        <>
            <AuthLayoutRoot>
                <AuthLayoutContent>
                    <Outlet />
                </AuthLayoutContent>
            </AuthLayoutRoot>
        </>
    );
};

export default AuthLayout;
import React, {useEffect, useState} from 'react';
import { columns } from './IpTableColumns';
import {ApiResponse, IpValue} from "../../types";
import { SubmissionsTable } from "./SubmissionsTable";
import useApi from "../../api/useApi";
import {getWhitelistedIpValues, postDeactivateIp} from "../../api/IpWhitelist/ipWhitelistApi";
import {ResultTypes} from "../../util/Constants";
import {GridRowId} from "@material-ui/data-grid";


export const IpSubmissionsTable = (props: any) => {

    // Component state
    const emptyDataArray: IpValue[] = [];
    const [ tableData, setTableData ] = useState(emptyDataArray);
    const [ activeData, setActiveData ] = useState(emptyDataArray);
    const [ displayedData, setDisplayedData ] = useState(emptyDataArray)
    const [ openSnackBar, setOpenSnackBar ] = useState(false);
    const [ snackBarSuccess, setSnackBarSuccess ] = useState(true);

    // API Requests
    const { loading: getDataLoading, request: getDataRequest } = useApi(getWhitelistedIpValues);
    const { loading: deactivationLoading, request: deactivationRequest } = useApi(postDeactivateIp)

    useEffect(() => {
        // Retrieve data for table on component load
        if (!deactivationLoading) {
            getData();
        }
    },[deactivationLoading]);

    const getData = async () => {
        // Handle response for retrieving table data
        const response = await getDataRequest();
        if (response.type === ResultTypes.Success) {
            const responseData: ApiResponse = response.value.data;
            const ipSubmissions: IpValue[] = responseData.data;
            ipSubmissions.map(
                (value: IpValue, index: number, ipValues: IpValue[]) => {
                    value.id = index;
                });

            setTableData(ipSubmissions);
            getActiveData(ipSubmissions);
        }
        else {
            setSnackBarSuccess(false);
            setOpenSnackBar(true);
        }
    };

    const getActiveData = (ipSubmissions: IpValue[]) => {
        let filteredArray = ipSubmissions
            .filter((value: IpValue, index: number) => {
                return value.isActive
            });

        setActiveData(filteredArray);
        setDisplayedData(filteredArray);
    }

    const showActiveOnly = (event: any) => {
        if (event.target.checked) {
            setDisplayedData(activeData);
        } else {
            setDisplayedData(tableData);
        }
    };

    // Make calls to API for deactivating submissions from whitelist
    const onDeactivatePress = async (rows: GridRowId[]) => {
        let filteredData: IpValue[] = tableData.filter((el, index) =>{
            return rows.indexOf(el.id) !== -1;
        });

        const response = await deactivationRequest(filteredData);
        if (response.type === ResultTypes.Success) {
            setSnackBarSuccess(true);
            setOpenSnackBar(true);
        }
        else {
            setSnackBarSuccess(false);
            setOpenSnackBar(true);
        }
    };

    // Callback method passed as prop to handle snackbar close
    const handleClose = (close: boolean) => {
        setOpenSnackBar(!close);
    };

    return (
        <SubmissionsTable
            columns={columns}
            tableData={displayedData}
            loading={getDataLoading}
            onDeactivatePress={onDeactivatePress}
            snackBarSuccess={snackBarSuccess}
            openSnackBar={openSnackBar}
            handleSnackBarClose={handleClose}
            showActiveOnly={showActiveOnly}
        />
    );
}

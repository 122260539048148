import Axios, {AxiosError, AxiosInstance} from "axios";
import { Api, AppLocalStore } from "../util/Constants";

const axiosRetry = require('axios-retry');

const apiClient: AxiosInstance = Axios.create({
  baseURL: Api.baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});


apiClient.interceptors.request.use(
  (request) => {
      // TODO: Wire up auth token
    const authToken = localStorage.getItem(AppLocalStore.Token);

    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }

    return request;
  },
  (error) => {
    // TODO Handle Errors.
    return error;
  }
);

axiosRetry(apiClient, {
    retries: 3, // number of retries
    retryDelay: (retryCount: number) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error: AxiosError) => {
        if (error && error.response) {
            // if retry condition is not specified, by default idempotent requests are retried
            console.log('Looking at retry condition', error.response.status !== 200);
            return error.response.status !== 200;
        }
    },
});

export default apiClient;

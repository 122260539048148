import React, { useEffect, useState } from "react";
import {
    Box, Button, Grid, MenuItem, TextField,
    RadioGroup, FormControl, FormLabel, Radio, FormControlLabel, CircularProgress, Snackbar, Alert
} from "@material-ui/core";
import { Form, Formik } from "formik";
import {postSubmitCountry} from "../../api/CountryWhitelist/countryWhitelistApi";
import useApi from "../../api/useApi";
import {ResultTypes} from "../../util/Constants";
import {ApiResponse, CountrySubmission, ParticipatingCustomers} from "../../types";
import {ObjectSchema} from "yup";
import {getCountryValidationSchema} from "./ValidationSchema";

const emailString = 'email';
const customerString = 'customer';

type CountryFormProps = {
    loadingCountries: boolean;
    countryList: string[];
    loadingCustomers: boolean;
    customersList: ParticipatingCustomers[];
}

const initialValues = {
    ticketNumber: '',
    country: '',
    isUserEmail: '',
    userEmail: '',
    customerName: '',
    reason: ''
}

const CountryForm = (props: CountryFormProps) => {

    // Component State
    const [submissionType, setSubmissionType] = useState(emailString);
    const [ openSnackBar, setOpenSnackBar ] = useState(false);
    const [ snackBarSuccess, setSnackBarSuccess ] = useState(true);
    const isUserEmailSubmission = submissionType === emailString;

    // Form validation schema
    const validationSchema: ObjectSchema<object | undefined, object> = getCountryValidationSchema(submissionType);

    // API Calls
    const { loading: uploadingSubmission, request: submissionRequest } = useApi(postSubmitCountry);

    const submit = async (values: {ticketNumber: string, country: string, isUserEmail: string, userEmail: string, customerName: string, reason: string},
                          {setSubmitting, setErrors, setStatus, resetForm}: any) => {
        // Find selected customer from list
        let selectedCustomer: ParticipatingCustomers | undefined
            = props.customersList.find( (cust: ParticipatingCustomers) => cust.id === values.customerName );

        // Convert values to submission object
        let submission: CountrySubmission = {
            TicketNumber: values.ticketNumber,
            Country: values.country,
            IsUserEmail: isUserEmailSubmission,
            UserEmail: isUserEmailSubmission ? values.userEmail : '',
            CustomerName: !isUserEmailSubmission && selectedCustomer ? selectedCustomer.companyName : '',
            CustomerId: !isUserEmailSubmission && selectedCustomer ? selectedCustomer.id : '',
            Reason: values.reason,
            SubmissionEmployee: ''
        };

        // Make API request
        const response = await submissionRequest(submission);
        const responseObject = response.value.data;

        // Show SnackBar on response
        if (response.type === ResultTypes.Success && responseObject.success) {
            setSnackBarSuccess(true);
            setOpenSnackBar(true);
            resetForm(initialValues);
        }
        else {
            setSnackBarSuccess(false);
            setOpenSnackBar(true);
        }
    };

    const handleRadioChange = (event: any) => {
        setSubmissionType(event.target.value);
    }

    const handleClose = (event: React.SyntheticEvent) => {
        setOpenSnackBar(false);
    };

    return (
        <Box>
            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}>
                <Alert onClose={handleClose} severity={snackBarSuccess ? 'success' : 'error'}>
                    {snackBarSuccess ? 'Submission Successful!' : 'There was an error submitting to server!'}
                </Alert>
            </Snackbar>
            {props.loadingCountries || props.loadingCustomers ?
                <CircularProgress style={{marginLeft: '50vw', marginTop: '20vh'}} />
                :
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (

                        <Form onSubmit={handleSubmit}>
                            <Box style={styles.container}>
                                <Grid container
                                      spacing={3}
                                      direction="column"
                                      alignItems="center"
                                >
                                    <Grid item xs={12} style={styles.gridItem}>
                                        <TextField
                                            id="ticketNumber"
                                            label="Ticket Number"
                                            name="ticketNumber"
                                            style={styles.textField}
                                            onChange={handleChange}
                                            value={values.ticketNumber}
                                            error={Boolean(touched.ticketNumber && errors.ticketNumber)}
                                            helperText={touched.ticketNumber && errors.ticketNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={styles.gridItem}>
                                        <TextField
                                            id="country"
                                            label="Country"
                                            name="country"
                                            style={styles.textField}
                                            value={values.country}
                                            select
                                            onChange={handleChange}
                                            error={Boolean(touched.country && errors.country)}
                                            helperText={touched.country && errors.country}
                                        >
                                            {props.countryList.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} style={styles.gridItem}>
                                        <FormControl component="fieldset" >
                                            <FormLabel component="legend">Submission Type</FormLabel>
                                            <RadioGroup aria-label="quiz" name="quiz" value={submissionType} onChange={handleRadioChange}>
                                                <FormControlLabel value={emailString} control={<Radio />} label="User Email" />
                                                <FormControlLabel value={customerString} control={<Radio />} label="Customer" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {isUserEmailSubmission ?
                                        <Grid item xs={6} style={styles.gridItem}>
                                            <TextField
                                                id="userEmail"
                                                label="User Email"
                                                name="userEmail"
                                                style={styles.textField}
                                                onChange={handleChange}
                                                value={values.userEmail}
                                                error={Boolean(touched.userEmail && errors.userEmail)}
                                                helperText={touched.userEmail && errors.userEmail}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={6} style={styles.gridItem}>
                                            <TextField
                                                id="customerName"
                                                label="Customer"
                                                name="customerName"
                                                style={styles.textField}
                                                select
                                                onChange={handleChange}
                                                value={values.customerName}
                                                error={Boolean(touched.customerName && errors.customerName)}
                                                helperText={touched.customerName && errors.customerName}
                                            >
                                                {props.customersList.map((option) => (
                                                    <MenuItem key={option.companyName} value={option.id}>
                                                        {option.companyName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    }
                                    <Grid item xs={6} style={styles.gridItem}>
                                        <TextField
                                            id="reason"
                                            label="Reason"
                                            name="reason"
                                            multiline={true}
                                            style={styles.textField}
                                            onChange={handleChange}
                                            value={values.reason}
                                            error={Boolean(touched.reason && errors.reason)}
                                            helperText={touched.reason && errors.reason}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box style={styles.submitButtonContainer}>
                                <Button
                                    title="Submit"
                                    variant="contained"
                                    type="submit"

                                >
                                    Submit
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            }
        </Box>
    );
}

const styles = {
    container: {
        justifyContent: 'center',
        paddingTop: 30
    },
    submitButtonContainer: {
        justifyContent: 'center',
        paddingTop: 30,
        display: 'flex'
    },
    gridItem: {
        width: '30%'
    },
    textField: {
        width: '100%',
        backgroundColor: 'white'
    },
    reasonGridItem: {
        width: '100%',
        height: 30
    },
    reasonTextField: {
        width: '100%',
        height: '100%'
    }
};

export default CountryForm;
import React, {useEffect, useState} from 'react';
import {Box, Card} from '@material-ui/core';
import IpAddressForm from '../components/Forms/IpAddressForm';
import CountryForm from '../components/Forms/CountryForm';
import {TabBar} from '../components/TabBar';
import useApi from "../api/useApi";
import {getParticipatingCustomers} from "../api/IpWhitelist/ipWhitelistApi";
import {getFgCountryList} from "../api/CountryWhitelist/countryWhitelistApi";
import {ResultTypes} from "../util/Constants";
import {ApiResponse, ParticipatingCustomers} from "../types";

export const Submissions = (props: any) => {
    const emptyStringArray: string[] = []
    const emptyCustomerArray: ParticipatingCustomers[] = []
    const [activeForm, setActiveForm] = React.useState(0);
    const [ countryList, setCountryList ] = useState(emptyStringArray);
    const [ customersList, setCustomerList ] = useState(emptyCustomerArray);

    // API Calls
    const { loading: loadingCountries, request: getCountriesRequest } = useApi(getFgCountryList);
    const { loading: loadingCustomers, request: getCustomersRequest } = useApi(getParticipatingCustomers);

    useEffect(() => {
        getCountryList();
        getParticipatingCustomersList();
    }, []);

    const getCountryList = async () => {
        const response = await getCountriesRequest();
        if (response.type === ResultTypes.Success) {
            const responseData: ApiResponse = response.value.data;
            const countryStringArray: string[] = responseData.data;
            setCountryList(countryStringArray);
        }
        else {
        }
    };

    const getParticipatingCustomersList = async () => {
        const response = await getCustomersRequest();
        if (response.type === ResultTypes.Success) {
            const responseData: ApiResponse = response.value.data;
            const customerArray: ParticipatingCustomers[] = responseData.data;
            setCustomerList(customerArray);
        }
        else {
        }
    };

    const handleTabChange = (newValue: number) => {
        setActiveForm(newValue);
    };

    return (
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
            <Box style={{width: '100%', alignItems: 'center', paddingTop: 30}}>
                <TabBar handlePress={handleTabChange}/>
            </Box>
            <Box style={{height: '100%', width: '100%'}}>
                {activeForm === 0 ?
                    <IpAddressForm
                        loadingCustomers={loadingCustomers}
                        customersList={customersList}
                    />
                    :
                    <CountryForm
                        countryList={countryList}
                        loadingCountries={loadingCountries}
                        loadingCustomers={loadingCustomers}
                        customersList={customersList}
                    />}
            </Box>
        </Box>
    );
}
/* eslint-disable */

import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: 'rgb(229, 229, 229, 0.05)',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#117492'
    },
    secondary: {
      main: '#adadad'
    },
    text: {
      primary: '#000000',
      secondary: '#172b4d'
    }
  },
  props: {
    // Name of the component
    Box: {
      // The default props to change
      display: 'flex',
    },
  },
  shadows,
  typography
});

export default theme;

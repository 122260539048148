import apiClient from "../ApiClient";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import * as endpoints from "./endpoints";
import {ApiResponse, CountrySubmission, CountryValue} from "../../types";

// Retrieve whitelisted country values
export const getWhitelistedCountryValues =
    (): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.get(endpoints.getCountryWhitelist);
    }

// Insert new country to whitelist
export const postSubmitCountry =
    (submission: CountrySubmission): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.post(endpoints.SubmitCountry, submission);
    }

// Deactivate country record
export const postDeactivateCountry =
    (submission: CountrySubmission): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.post(endpoints.DeactivateCountry, submission);
    }

// Retrieve FraudGuard list of countries
export const getFgCountryList =
    (): Promise<AxiosResponse<ApiResponse>> => {
        return apiClient.get(endpoints.FgCountryList);
    }
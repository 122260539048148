import React from "react";
import { SignInButton } from "../components/SignInButton";
import { Box, Card } from "@material-ui/core";
import PtgLogo from '../resources/ptg-logo.png';

export const LoginScreen = (props: any) => {
    return (
        <Box style={styles.container}>
            <Card style={{... styles.signInContainer, display: 'flex', flexDirection: 'column'}}>
                <Box style={styles.ptgLogoContainer}>
                    <img src={PtgLogo} style={styles.ptgLogo} />
                </Box>
                <Box style={styles.signInDiv}>
                    <SignInButton />
                </Box>
            </Card>
        </Box>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    signInContainer: {
        height: '45vh',
        width: '30vw',
        border: '1px solid grey',
        paddingTop: '50px'
    },
    signInDiv: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        marginTop: 30
    },
    header: {},
    ptgLogoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ptgLogo: {
        maxWidth: '100%',
        maxHeight: '100%',
        marginTop: 10,
    }
}
export const Api = Object.freeze({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    testUrl: process.env.REACT_APP_TEST_API_URL
});

export enum ResultTypes {
    Success = "success",
    Error = "error"
}

export enum AppLocalStore {
    Token = "token",
    User = "user"
}
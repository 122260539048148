import React, {useEffect, useState} from 'react';
import { columns } from './CountryTableColumns';
import useApi from "../../api/useApi";
import {getWhitelistedCountryValues, postDeactivateCountry} from "../../api/CountryWhitelist/countryWhitelistApi";
import {ResultTypes} from "../../util/Constants";
import {ApiResponse, CountryValue, IpValue} from "../../types";
import {SubmissionsTable} from "./SubmissionsTable";
import {GridRowId} from "@material-ui/data-grid";


export const CountrySubmissionsTable = (props: any) => {

    // Component state
    const emptyDataArray: CountryValue[] = [];
    const [ tableData, setTableData ] = useState(emptyDataArray);
    const [ activeData, setActiveData ] = useState(emptyDataArray);
    const [ displayedData, setDisplayedData ] = useState(emptyDataArray)
    const [ openSnackBar, setOpenSnackBar ] = useState(false);
    const [ snackBarSuccess, setSnackBarSuccess ] = useState(true);

    // API Requests
    const { loading: getDataLoading, request: getDataRequest } = useApi(getWhitelistedCountryValues);
    const { loading: deactivationLoading, request: deactivationRequest } = useApi(postDeactivateCountry)


    useEffect(() => {
        if (!deactivationLoading) {
            getData();
        }
    },[deactivationLoading]);


    const getData = async () => {
        const response = await getDataRequest();
        if (response.type === ResultTypes.Success) {
            const responseData: ApiResponse = response.value.data;
            const countrySubmissions: CountryValue[] = responseData.data;
            countrySubmissions.map(
                (value: CountryValue, index: number) => {
                value.id = index;
            });

            setTableData(countrySubmissions);
            getActiveData(countrySubmissions);
        }
        else {
            setSnackBarSuccess(false);
            setOpenSnackBar(true);
        }
    };

    const onDeactivatePress = async (rows: GridRowId[]) => {
        let filteredData: CountryValue[] = tableData.filter((el, index) =>{
            return rows.indexOf(el.id) !== -1;
        });

        const response = await deactivationRequest(filteredData);
        if (response.type === ResultTypes.Success) {
            setSnackBarSuccess(true);
            setOpenSnackBar(true);
        }
        else {
            setSnackBarSuccess(false);
            setOpenSnackBar(true);
        }
    };

    const getActiveData = (countrySubmissions: CountryValue[]) => {
        let filteredArray = countrySubmissions
            .filter((value: CountryValue, index: number) => {
                return value.isActive
            });

        setDisplayedData(countrySubmissions);
        setActiveData(filteredArray);
    };

    const showActiveOnly = (event: any) => {
        const setActive = event.target.checked;

        if (setActive) {
            setDisplayedData(activeData);
        } else {
            setDisplayedData(tableData);
        }
    };

    const handleClose = (close: boolean) => {
        setOpenSnackBar(!close);
    };

    return (
        <SubmissionsTable
            columns={columns}
            tableData={displayedData}
            loading={getDataLoading}
            onDeactivatePress={onDeactivatePress}
            snackBarSuccess={snackBarSuccess}
            openSnackBar={openSnackBar}
            handleSnackBarClose={handleClose}
            showActiveOnly={showActiveOnly}
        />
    );
}
